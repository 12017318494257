import service from '../utils/request';

// : {
//   orderStatus?: 'CANCELED' | 'COMPLETED' | 'CONTACTING' | 'NOT_CONTACT' | string;
//   pageNum: number;
//   pageSize: number;
// }
export function myOrder(params) {
  return service({
    url: '/order/myOrder',
    method: 'POST',
    params
  })
}

export function detail(orderId) {
  return service({
    url: '/order/detail',
    method: 'POST',
    params: {
      orderId
    }
  })
}

export function projectDetail({ id }) {
  return service({
    url: '/project/detail',
    method: 'POST',
    params: {
      id
    }
  })
}