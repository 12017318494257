<!--购物车-->
<template>
  <div class="page-cart">
    <Breadcrumb
      :data="[
        {
          name:'My Order',
          to:{
            name:'order'
          }
        },
        {
          name:'Order Detail'
        }
      ]"
    ></Breadcrumb>

    <div class="common-gap"></div>

    <div class="bottom-content">
      <div class="message-box">
        <div class="common-title">
          <div class="_left">Order Detail</div>
        </div>
        <div class="detail-message">
          <div class="order-id">
            Order NO：{{ orderDetail.orderNo }} <span>{{ orderDetail.orderStatus | StatusText}}</span>
          </div>
          <div class="order-time">Order Time：{{ orderDetail.orderTime }}</div>
          <div class="related-project">
            Project：<span @click="toProject" style="color: #43328F;cursor: pointer">{{ orderDetail.projectName }}</span>
          </div>
          <div class="address">Address：{{ orderDetail.address }}</div>
          <div class="email">Email：{{ orderDetail.contactEmail }}</div>
        </div>
      </div>

      <div class="goods-box">
        <div class="common-title">
          <div class="_left">Order Goods</div>
        </div>
        <table class="goods-table">
          <thead>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>quantity</th>
            <th>Price</th>
            <th>Amounts</th>
          </tr>
          </thead>
          <tr :key="good.id" class="tr" v-for="good in orderDetail.items">
            <td class="td">
              <el-image class="image" :src="good.url" fit="contain"></el-image>
            </td>
            <td class="td">
              <div class="name">{{ good.productName }}</div>
            </td>
            <td class="td">
              <div class="count">{{ good.amount }}</div>
            </td>
            <td class="td">
              <div class="price">
                <template v-if="good.productInPromotion==='Y'">
                  <span class="dle">{{ good.productPrice /100 }}$</span>
                  <span>{{ good.productPromotionPrice /100  }}$</span>
                </template>
                <template>
                  {{ good.productPrice /100 }}$
                </template>
              </div>
            </td>
            <td class="td">
              <div class="total">{{ good.total /100 }}$</div>
            </td>
          </tr>
        </table>
      </div>
      <div class="process-box">
        <div class="common-title">
          <div class="_left">Execution</div>
        </div>

<!--        <template v-for="(item,index) in orderDetail.groupEx">-->
<!--          <div :key="index">-->
<!--            <div class="title">{{ index }}</div>-->
<!--          </div>-->
<!--        </template>-->
        <div class="item" :key="key" v-for="(value,key) in orderEx">
          <div class="info">{{ key | showStep }}</div>
          <div class="media-list">
            <div class="media" :key="item.id" v-for="item in value">
              <div style="display: flex;flex-direction: column;justify-content: center">
                <el-image v-if="checkImgType(item.picUrl)" class="image" :src="item.picUrl" fit="contain"></el-image>
                <el-image v-else class="image files" :src="require('@/assets/images/file.png')" fit="contain"></el-image>
              </div>

              <!--            <div v-else class="image" >aaa</div>-->
              <div class="_right">
                <div class="name">{{ item.picName }}</div>
                <el-button @click="download(item)" type="text">Download</el-button>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import { detail } from '@/api/order'
import XEUtils from 'xe-utils'
import image  from '@/assets/images/file.png'

// 订单流程：Execution
// 订单三种状态翻译：
// 未沟通：Contacting
// 已沟通：Processing
// 完成：Complete

// 1. 原始合同Contract； 2. 原材料证明 Raw Materials' Certificates/Testing Reports；3. 质检计划 Inspection Plan；4. QA/QC记录 QA/QC Records；5. 生产加工过程（照片、视频）Production Process；6. 包装方案 Packing；7. 物流信息 Logistics

export default {
  components: { Breadcrumb },
  filters: {
    StatusText(value) {
      switch (value) {
        case null:
        case '':
          return 'NONE';
        case 'CANCELED':
          return 'Canceled';
        case 'COMPLETED':
          return 'Completed';
        case 'CONTACTING':
          return 'Processing';
        case 'NOT_CONTACT':
          return 'Contacting';
      }
    },
    showStep(value){
      switch (Number(value)) {
        case 1:
          return 'Contract';
        case 2:
          return 'Raw Materials\' Certificates/Testing Reports';
        case 3:
          return 'Inspection Plan';
        case 4:
          return 'QA/QC Records';
        case 5:
          return 'Production Process';
        case 6:
          return 'Packing';
          case 7:
          return 'Logistics';
      }
    }

  },
  data() {
    return {
      orderDetail: {
        orderNo: '12546',
        orderState: '待确定',
        orderTime: '2023-02-23 18:19:90 ',
        relatedProject: '万达集团万达商场',
        contactEmail: '万达集团万达商场',
        email: '2550000000@qq.com',
        goodsList: [
          {
            id: 1,
            name: 'Product1',
            url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            price: 125,
            count: 1,
          },
          {
            id: 2,
            name: 'Product2',
            url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            count: 2,
            price: 125,
          },
          {
            id: 3,
            name: 'Product3',
            url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            price: 125,
            count: 3,
          },
        ],
      },
      orderId: '',
      orderEx:{},
    };
  },

  created() {
    this.orderId = this.$route.query.id;
    this.queryData()
  },

  methods: {
    checkImgType(fileName) { //判断是否是图片
      //用文件名name后缀判断文件类型，可用size属性判断文件大小不能超过500k ， 前端直接判断的好处，免去服务器的压力。
      return /\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(fileName);
    },
    toProject() {
      this.$router.push({
        name: 'project',
        query: {
          pid: this.orderDetail.projectId,
          oid: this.orderDetail.id
        }
      })
    },
    download(item) {
      let a = document.createElement('a');
      a.href = item.picUrl + '?response-content-type=application/octet-stream';
      a.download = item.picName;
      a.target = '_blank'
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    async queryData() {
      const res = await detail(this.orderId)
      if (res.data) {
        res.data.items = res.data.items.map(items => {

          if (items.productImageId) {
            const list = items.productImageId.split(',')
            if (list && list.length > 0) {
              items.url = list[0]
            }

          }
          return items

        })

        if(res.data.pics){
          res.data.groupEx = XEUtils.groupBy(res.data.pics,'stepNo')
        }

        this.orderDetail = res.data
        this.orderEx = XEUtils.groupBy(res.data.pics,'stepNo')
      }

    }
  },
  computed: {},
  watch: {},
};
</script>

<style lang="less" scoped>
.bottom-content {
  background: var(--main-color-bg-gray);
  padding: 22px 55px 268px;
}

.common-title {
  height: 21px;

  ._left {
    font-size: 26px;
    font-weight: 400;
    color: #333333;
    line-height: 26px;
  }
}

.message-box {
  padding: 23px 22px 36px;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  box-shadow: 3px 3px 7px 0px rgba(198, 198, 198, 0.3);
  border-radius: 12px;

  .detail-message {
    font-size: 22px;
    font-weight: 400;
    color: #555555;
    margin-top: 22px;

    div {
      margin-top: 22px;
    }

    .order-id {
      display: flex;
      align-items: center;

      span {
        //width: 118px;
        padding: 0 10px;
        height: 40px;
        line-height: 40px;
        background: #f56c6c;
        border-radius: 12px;
        margin-left: 30px;
        font-size: 22px;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.goods-box {
  padding: 23px 22px 36px;
  margin-top: 22px;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  box-shadow: 3px 3px 7px 0px rgba(198, 198, 198, 0.3);
  border-radius: 12px;

  .goods-table {
    font-size: 22px;
    font-weight: 400;
    color: #555555;

    tr {
      th {
        font-weight: 400;
        min-width: 220px;
      }
    }

    thead {
      tr {
        height: 64px;
      }
    }

    .tr {
      margin-top: 30px;

      .td {
        vertical-align: middle;
        min-width: 250px;
        text-align: center;

        .image {
          width: 230px;
          height: 230px;
        }
      }
    }
  }
}

.process-box {
  padding: 23px 22px 36px;
  margin-top: 22px;
  font-size: 22px;

  background: #ffffff;
  border: 1px solid #f5f5f5;
  box-shadow: 3px 3px 7px 0px rgba(198, 198, 198, 0.3);
  border-radius: 12px;

  .item {
    padding: 20px;
    padding-left: 40px;

    .info {
      font-weight: bold;
      color: #555555;
      font-size: 22px;
      margin-bottom: 20px;
    }

    .media{
      flex: 0 0 16%;
      margin-right: .6%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      >._right{
        font-size: 16px;
        text-align: center;
        //margin-left: 100px;
      }
    }

    /deep/ .el-button {
      font-size: 20px;
    }

    .image {
      width: 100px;
      height: 100px;

      &.files{
        width: 100px;
        height: 100px;
      }
    }
  }

}

.dle {
  text-decoration: line-through;
  margin-right: 20px;
}

.media-list{
  display: flex;
  flex-wrap: wrap;
}
</style>
